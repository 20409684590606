<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center" class="row-alignment">
      <v-row justify="center" v-if="!editone && !edittwo">
        <v-col class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <v-card
            class="images-container-card card-height"
            v-if="UserProfileCard"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-card-text class="text-left">
                  <h2 class="title_style">
                    {{
                      !getIsSupplier() ? "Store Overview" : "Factory Overview"
                    }}
                  </h2>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container"> </v-col>
              <v-col cols="12" md="12">
                <v-avatar
                  size="150"
                  v-if="!preview"
                  :class="avatar ? 'image_style' : 'preview_image_style'"
                >
                  <div
                    v-if="!imageUrl"
                    style="width: 100%; height: 100%; background-color: #eef1fa"
                  >
                    <v-icon style="color: #442d65; font-size: 80px">
                      {{ !getIsSupplier() ? "mdi-home-modern" : "mdi-factory" }}
                    </v-icon>
                  </div>
                  <v-img v-else :src="imageUrl" />
                </v-avatar>
                <v-avatar size="150" v-else>
                  <v-img :src="preview" />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="12" class="text-center pt-0">
                <md-button @click="editLogo" class="logo_btn_style"
                  >Update logo</md-button
                >
                <input
                  type="file"
                  ref="logoInput"
                  style="display: none"
                  accept="image/jpeg,image/png,image/jpg"
                  @change="handleLogoUpload"
                />
                <v-divider justify="center" class="divider_style" />
              </v-col>
              <v-col
                cols="12"
                class="text-left ml-7 col-10"
                style="line-height: 40px"
              >
                <div>
                  <span class="subtitle_data">
                    {{ !getIsSupplier() ? "Store name" : "Factory name" }} :
                  </span>
                  <span class="subtitle_data font-weight-bold">
                    {{ Show.name }}
                  </span>
                </div>
                <div v-if="Show.companyWebsite">
                  <span class="subtitle_data"> Website: </span>
                  <a
                    class="subtitle_data font-weight-bold"
                    :href="
                      Show.companyWebsite.startsWith('http://') ||
                      Show.companyWebsite.startsWith('https://')
                        ? Show.companyWebsite
                        : '//' + Show.companyWebsite
                    "
                    target="_blank"
                  >
                    {{ Show.companyWebsite }}
                  </a>
                </div>
                <div v-if="Show.registrationNumber">
                  <span class="subtitle_data"> Registration Number : </span>
                  <span class="subtitle_data font-weight-bold">
                    {{ Show.registrationNumber }}
                  </span>
                </div>
                <div
                  v-if="
                    Show.establishedCountry &&
                    Show.establishedCity &&
                    Show.establishedYear
                  "
                >
                  <span class="subtitle_data"> Established Year : </span>
                  <span class="subtitle_data font-weight-bold">
                    {{ Show.establishedYear }}
                  </span>
                </div>
                <div v-if="Show.establishedCountry && Show.establishedCity">
                  <span class="subtitle_data"> Location : </span>
                  <span class="subtitle_data font-weight-bold">
                    {{ Show.establishedCountry }}, {{ Show.establishedCity }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          class="col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 col"
          v-if="companyOverview"
        >
          <v-card class="data-container-card card-height">
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left">
                  <p class="title_style title_align">
                    {{ !getIsSupplier() ? "Store Details" : "Factory Details" }}
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="edittwoClicked" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider class="company_overview_divider_style" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" style="margin-top: -18px">
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.companyMission"
                >
                  <p class="subtitle_data">Company Mission :</p>
                  <p class="paragraph_data font-weight-bold">
                    {{ Show.companyMission }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.companyVision"
                >
                  <p class="subtitle_data">Company Vision :</p>
                  <p class="paragraph_data">
                    {{ Show.companyVision }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.valueAddedServices && getIsSupplier()"
                >
                  <p class="subtitle_data">Value Added Services :</p>
                  <p class="paragraph_data">
                    {{ Show.valueAddedServices }}
                  </p>
                </v-col>
              </v-col>

              <v-col cols="6" v-if="form.selectedProductTypes">
                <div v-if="Show.products.length" class="products-container">
                  <div v-if="Show.products.length" class="md-layout-item">
                    <div class="subtitle_data data-margin">Products :</div>
                    <div
                      class="subtitle_data font-weight-bold data-margin"
                      v-for="selected in Show.products"
                      :key="selected"
                    >
                      {{ getProductTypeName(selected) }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                Show.employeeCount ||
                Show.machineCount ||
                Show.productionCapacity
              "
            >
              <v-col cols="12">
                <div class="more-info-style">
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.employeeCount && getIsSupplier()"
                  >
                    <span class="subtitle_data"> Employees : </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.employeeCount }}
                    </span>
                  </div>
                  <v-divider
                    v-if="Show.employeeCount && getIsSupplier()"
                    vertical
                    class="vertical-hr"
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.machineCount && getIsSupplier()"
                  >
                    <span class="subtitle_data"> Machines : </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.machineCount }}
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="Show.machineCount && getIsSupplier()"
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.productionCapacity"
                  >
                    <span class="subtitle_data">{{
                      !getIsSupplier() ? "Sale Capacity" : "Production Capacity"
                    }}</span>
                    <span class="subtitle_data ml-1">
                      {{ Show.productionCapacity }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                (Show.sampleLeadTime || Show.productionLeadTime) &&
                getIsSupplier()
              "
            >
              <v-col cols="12">
                <div class="more-info-style">
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.sampleLeadTime && getIsSupplier()"
                  >
                    <span class="subtitle_data"> Sample Lead Time : </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.sampleLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="Show.sampleLeadTime == 1"
                        >Week</span
                      >
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="
                      Show.sampleLeadTime &&
                      Show.productionLeadTime &&
                      getIsSupplier()
                    "
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.productionLeadTime && getIsSupplier()"
                  >
                    <span class="subtitle_data"> Production Lead Time : </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.productionLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="Show.productionLeadTime == 1"
                        >Week</span
                      >
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="
                  (Show.productionLeadTime || Show.sampleLeadTime) &&
                  getIsSupplier()
                "
              >
              </v-col>
            </v-row>
            <v-row v-if="Show.sampleDevelopmentCondition && getIsSupplier()">
              <v-col cols="6" class="text-left ml-8">
                <p class="subtitle_data">Sample Development Condition :</p>
                <p class="paragraph_data">
                  {{ Show.sampleDevelopmentCondition }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="Show.bulkManufacturingCondition && getIsSupplier()">
              <v-col cols="6" class="text-left ml-8">
                <p class="subtitle_data">Bulk Manufacturing Condition :</p>
                <p class="paragraph_data">
                  {{ Show.bulkManufacturingCondition }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="!editone && !edittwo">
        <v-col
          class="col-12 col-md-4"
          :class="{
            'col-lg-6 col-xl-6': getIsSupplier(),
            'col-lg-12 col-xl-12': !getIsSupplier(),
          }"
          v-if="contactInfo"
        >
          <v-card
            :class="
              Show.contactDetails.length
                ? 'images-container-card card-height'
                : 'data-container-card card-height'
            "
          >
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left ml-4">
                  <h2 class="title_style">Contact Details</h2>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="editfour = true" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider class="company_overview_divider_style" />
              </v-col>
              <v-col
                md="12"
                align="center"
                class="text-left ml-8 mb-4"
                v-for="contact in Show.contactDetails"
                :key="contact.id"
              >
                <div>
                  <span class="subtitle_data"> Name: </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.name }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data"> Title: </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.designation }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data"> Email Address: </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.email }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data"> Phone Number: </span>
                  <span class="subtitle_data font-weight-bold ml-1">
                    {{ contact.mobile_number }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-dialog
              v-model="editfour"
              persistent
              content-class="v-dialog-data"
            >
              <v-card class="v-card-data">
                <v-card-title>
                  <p class="title_style">Team Contact Details</p>
                  <v-divider justify="center" class="dialog_divider_style" />
                </v-card-title>
                <v-form ref="contactDetails">
                  <v-container class="py-0 team-details">
                    <v-row>
                      <v-col
                        md="12"
                        v-for="(contact, index) in form.contactobj"
                        :key="index"
                      >
                        <v-row>
                          <v-col md="3">
                            <v-text-field
                              :label="`Name`"
                              v-model="contact.name"
                              :rules="rules.contactNameRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="3">
                            <v-text-field
                              :label="`Title`"
                              v-model="contact.title"
                              :rules="rules.contactTitleRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="3">
                            <v-text-field
                              :label="`Email`"
                              v-model="contact.email"
                              :rules="rules.contactEmailRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col md="1" style="z-index: 9999">
                            <vue-country-code
                              style="height: 40px; margin-right: 0px"
                              @onSelect="onSelect(...arguments, index)"
                              :defaultCountry="contact.country"
                            >
                            </vue-country-code>
                          </v-col>

                          <v-col md="2">
                            <v-text-field
                              style="width: 250px; margin-left: 0px"
                              :label="`Phone Number`"
                              v-model="contact.number"
                              :prefix="contact.dialCode + ' '"
                              :rules="rules.contactPhoneNumberRules"
                              v-on:keypress="PhoneInputNumbersOnly"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <div class="tooltip mb-5 ml-3">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          @click="addContactInput"
                          x-small
                          color="#70D0CE"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Add Contact</span>
                      </div>
                      <div class="tooltip mb-5">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          v-if="form.contactobj.length > 1"
                          @click="removeContactInput"
                          x-small
                          color="#EA7A66"
                        >
                          <v-icon dark> mdi-minus </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Remove Contact</span>
                      </div>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <md-button class="close-btn" @click="editfour = false"
                    >Close
                  </md-button>
                  <md-button class="save-btn" @click="updateContacts"
                    >Save
                  </md-button>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col
          class="col-12 col-md-8 col-lg-6 col-xl-6"
          v-if="machineInfo && getIsSupplier()"
        >
          <v-card
            class="machines-container-card"
            :class="
              Show.machines.length
                ? 'machines-container-card card-height'
                : 'data-container-card card-height'
            "
          >
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left">
                  <p class="title_style title_align">Machines</p>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="editfive = true" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-divider
                  justify="center"
                  class="company_overview_divider_style"
                />
              </v-col>
              <v-row
                v-if="Show.machines.length"
                class="machines-data-container col-12 col-md-7 col-lg-7 col-xl-7"
              >
                <v-col>
                  <div class="profile-card-style">
                    <div
                      class="md-layout-item machine-type subtitle_data font-weight-bold"
                    >
                      Machine Type
                    </div>
                    <v-divider vertical class="vertical-hr" />
                    <div
                      class="md-layout-item amount subtitle_data font-weight-bold"
                    >
                      Amount
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="machines-data-container mt-0 col-12 col-md-7 col-lg-7 col-xl-7"
              >
                <v-col
                  v-for="machine in Show.machines"
                  :key="machine.id"
                  class="machines-container-alignment col-12"
                >
                  <div class="profile-card-style">
                    <div class="md-layout-item machine-type subtitle_data">
                      <div>{{ machine.machine_type }}</div>
                    </div>
                    <v-divider vertical class="vertical-hr" />
                    <div class="md-layout-item amount subtitle_data">
                      <div>{{ machine.number_of_machines }}</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
            <v-dialog
              v-model="editfive"
              persistent
              content-class="v-dialog-data"
            >
              <v-card class="v-card-data">
                <v-card-title>
                  <p class="title_style mb-5">Machines</p>
                  <v-divider justify="center" class="dialog_divider_style" />
                </v-card-title>
                <v-form ref="machineDetails">
                  <v-container class="py-0 machine-details">
                    <v-row>
                      <v-col
                        md="12"
                        v-for="(type, index) in form.machineobj"
                        :key="index"
                      >
                        <v-row>
                          <v-col md="6">
                            <v-text-field
                              :label="`Machine Type: ${index + 1}`"
                              v-model="type.type"
                              hint="Type of Machine"
                              outlined
                              dense
                              :rules="rules.machineRequiredRules"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              :label="`Machine Amount: ${index + 1}`"
                              v-model="type.amount"
                              hint="Amount of Machines"
                              outlined
                              dense
                              type="number"
                              min="0"
                              :rules="rules.machineRequiredRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <div class="tooltip mb-5 ml-1">
                        <v-btn
                          class="mr-4 mb-4 ml-2 add-remove-btn"
                          @click="addMachineInput"
                          x-small
                          color="#70D0CE"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Add Machine</span>
                      </div>
                      <div class="tooltip mb-5">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          v-if="form.machineobj.length > 1"
                          @click="removeMachineInput"
                          x-small
                          color="#EA7A66"
                        >
                          <v-icon dark> mdi-minus </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Remove Machine</span>
                      </div>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <md-button class="close-btn" @click="editfive = false"
                    >Close
                  </md-button>
                  <md-button class="save-btn" @click="updateMachine"
                    >Save
                  </md-button>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
      <v-col class="user-details ml-2 col-12" v-if="editone"> </v-col>
      <v-col md="11" class="ml-2" v-if="edittwo">
        <div class="user-details-container">
          <v-card class="company-details-v-card">
            <v-card-title>
              <p class="title_style ml-3">
                {{ !getIsSupplier() ? "Store Details" : "Factory Details" }}
              </p>
            </v-card-title>
            <v-card-text height="1000px" class="pt-0">
              <v-container class="py-0">
                <v-form ref="CompanyDetails" class="mt-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Registration Number
                      </p>
                      <v-text-field
                        v-model="form.registrationNumber"
                        outlined
                        dense
                        :rules="rules.registrationRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        {{ !getIsSupplier() ? "Store name" : "Factory name" }}
                      </p>
                      <v-text-field
                        v-model="form.name"
                        :rules="rules.factoryNameRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Website URL
                      </p>
                      <v-text-field
                        v-model="form.companyWebsite"
                        persistent-hint
                        :rules="rules.websiteRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Year of Company Establishment
                      </p>
                      <v-select
                        v-model="form.establishedYear"
                        :items="yearArray"
                        item-text="year"
                        item-value="year"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Established Country
                      </p>
                      <v-autocomplete
                        ref="country"
                        v-model="form.establishedCountry"
                        :items="countries"
                        outlined
                        dense
                        placeholder="Select..."
                      >
                        <template v-slot:item="{ item }">
                          <span>{{ item }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>{{ item }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Established City
                      </p>
                      <v-text-field
                        v-model="form.establishedCity"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="12">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Value Added Services
                      </p>
                      <v-textarea
                        v-model="form.valueAddedServices"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Vision
                      </p>
                      <v-textarea
                        v-model="form.companyVision"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Mission
                      </p>
                      <v-textarea
                        v-model="form.companyMission"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="12"
                      ><p class="text-left mb-1 ml-0 form-label-style">
                        Product Types
                      </p>
                      <v-select
                        class="selection-field"
                        v-model="form.selectedProductTypes"
                        :items="productTypes"
                        item-value="id"
                        multiple
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>{{ item.name + ", " }}</span>
                        </template>
                      </v-select>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-divider class="dialog_divider_style" />
                    </v-col>

                    <v-col v-if="getIsSupplier()" cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Number of Employees
                      </p>
                      <v-text-field
                        v-model="form.employeeCount"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Number of Machines
                      </p>
                      <v-text-field
                        v-model="form.machineCount"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        {{
                          !getIsSupplier()
                            ? "Sale Capacity"
                            : "Production Capacity"
                        }}
                      </p>
                      <v-text-field
                        v-model="form.productionCapacity"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Sample Lead Time (Weeks)
                      </p>
                      <v-text-field
                        v-model="form.sampleLeadTime"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Production Lead Time (Weeks)
                      </p>
                      <v-text-field
                        v-model="form.productionLeadTime"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Sample Development Condition
                      </p>
                      <v-textarea
                        v-model="form.sampleDevelopmentCondition"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col v-if="getIsSupplier()" cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Bulk Manufacturing Condition
                      </p>
                      <v-textarea
                        v-model="form.bulkManufacturingCondition"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <md-button class="close-btn" @click="edittwoClosed"
                >Close
              </md-button>
              <md-button class="save-btn" @click="updateCompany"
                >Save
              </md-button>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-9 col-12 ml-0">
      <v-col v-if="companyGallery">
        <factory-profile-image
          imageCategory="Company Gallery"
          :companyImages="this.Show.companyImages"
          :baseUrl="this.baseUrl"
          @refresh-factory="getFactory"
        />
        <factory-profile-image
          imageCategory="Products"
          :companyImages="this.Show.productImages"
          :baseUrl="this.baseUrl"
          @refresh-factory="getFactory"
        />
        <factory-profile-image
          imageCategory="Certifications"
          :companyImages="this.Show.certificationImages"
          :baseUrl="this.baseUrl"
          @refresh-factory="getFactory"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import notification from "../../../notification";
import restAdapter from "../../../restAdapter";
import * as conf from "../../../conf.yml";
import countryList from "country-list";
import FileUpload from "../../../components/FileUpload.vue";
import utils from "../../../utils";
import codes from "country-codes-list";
import FactoryProfileImage from "./FactoryProfileImages.vue";

import {
  confirmBox,
  setLeaveAlert,
  resetLeaveAlert,
} from "../../../utils/confirmBox";
import VComponent from "@/components/base/VComponent.vue";
import Vue from "vue";
import auth from "../../../auth";
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

/* eslint-disable */
export default {
  components: {
    VComponent,
    FileUpload,
    FactoryProfileImage,
  },
  name: "UserProfile",
  data() {
    return {
      billAsDelivery: false,
      country: "",
      preview: null,
      image: null,
      oldNum: 0,
      user: {},
      imageUrl: null,
      countries: [],
      countryCodes: [],
      arr: [],
      countryCodeList: [],
      showBillingAddress: true,
      showDeliveryAddress: true,
      activePicker: null,
      menu: false,
      avatar: false,
      productTypes: [],
      baseUrl: null,
      yearArray: [],
      //Dialogs
      editone: false,
      edittwo: false,
      editthree: false,
      editfour: false,
      editfive: false,
      editsix: false,
      length: null,
      //Images
      files: [],
      fileInput: [],
      imageCategories: ["Company Gallery", "Products", "Certifications"],
      acceptedFileTypes: utils.acceptedFileTypes,
      showOption: false,
      UserProfileCard: true,
      companyOverview: true,
      contactInfo: true,
      machineInfo: true,
      companyGallery: true,
      Show: {
        //Company
        name: null,
        global_organization_id: null,
        companyMission: null,
        companyVision: null,
        companyWebsite: null,
        registrationNumber: null,
        establishedYear: null,
        establishedCity: null,
        establishedCountry: null,
        valueAddedServices: null,
        products: [],
        employeeCount: null,
        machineCount: null,
        productionCapacity: null,
        sampleLeadTime: null,
        productionLeadTime: null,
        bulkManufacturingCondition: null,
        sampleDevelopmentCondition: null,
        male: null,
        female: null,
        others: null,
        //Contacts
        contactDetails: [],
        //Machines
        machines: [],
        //Images
        images: [],
        companyImages: [],
        productImages: [],
        certificationImages: [],
      },
      form: {
        name: null,
        global_organization_id: null,
        establishedYear: null,
        establishedCity: null,
        establishedCountry: null,
        valueAddedServices: null,
        selectedProductTypes: [],
        companyVision: null,
        companyMission: null,
        companyWebsite: null,
        registrationNumber: null,
        employeeCount: null,
        machineCount: null,
        productionCapacity: null,
        sampleLeadTime: null,
        productionLeadTime: null,
        bulkManufacturingCondition: null,
        sampleDevelopmentCondition: null,
        male: null,
        female: null,
        others: null,
        //Machines
        machineobj: [],
        //Contact Details
        contactobj: [],
        //Company Hierarchy
        companyStructure: [],
        contactName: null,
        contactDesignation: null,
        contactEmail: null,
        contactNumber: null,
        //Image
        imageCaption: null,
        imageDescription: null,
        imageCategory: "Company Gallery",
        //Question Answers
        environmentalSelected: [],
      },
      rules: {
        factoryNameRules: [
          (value) =>
            !!value ||
            (this.getIsSupplier()
              ? "Factory name is required."
              : "Store name is required."),
          (value) =>
            (value && value.length <= 255) ||
            (this.getIsSupplier()
              ? "Factory name should have maximum 255 characters."
              : "Store name should have maximum 255 characters."),
        ],
        phoneNumberRules: [
          (value) =>
            !value ||
            (value && value.length >= 7 && value.length <= 16) ||
            "Phone number should have minimum 7 digits and maximum 16 digits",
        ],
        websiteRules: [
          (value) =>
            !value ||
            (value &&
              /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
                value
              )) ||
            "Invalid website url",
        ],
        establishedYearRules: [
          (value) =>
            !value ||
            (value && parseInt(value) <= 9999 && parseInt(value) >= 1000) ||
            "Year should have 4 characters",
        ],
        registrationRules: [
          (value) =>
            !value ||
            (value && value.length <= 150) ||
            "Must be no more than 150 characters",
        ],
        textAreaRules: [
          (value) =>
            !value ||
            (value && value.length <= 255) ||
            "Must be no more than 255 characters",
        ],
        contactNameRules: [
          (value) => !!value || "Name is required.",
          (value) =>
            !value ||
            (value && value.length >= 3) ||
            "Name should have at least 3 characters",
          (value) =>
            (value && value.length <= 150) ||
            "Name should have maximum 150 characters",
          (value) =>
            /^[A-Za-z\s]+$/.test(value) ||
            "Name must contain only letters and spaces",
        ],
        contactTitleRules: [
          (value) => !!value || "Title is required.",
          (value) =>
            (value && value.length <= 150) ||
            "Title should have maximum 150 characters",
          (value) =>
            /^[A-Za-z\s]+$/.test(value) ||
            "Title must contain only letters and spaces",
        ],
        contactEmailRules: [
          (value) => !!value || "Email is required.",
          (value) =>
            !value || (value && /\S+@\S+\.\S+/.test(value)) || "Invalid Email",
          (value) =>
            !value ||
            (value && value.length <= 150) ||
            "Email should have maximum 150 characters",
        ],
        contactPhoneNumberRules: [
          (value) => !!value || "Phone Number is required",
          (value) =>
            !value ||
            (value && value.length >= 7 && value.length <= 16) ||
            "Phone number should have minimum 7 digits and maximum 16 digits",
        ],
        machineRequiredRules: [(value) => !!value || "Required field"],
        captionRules: [(value) => !!value || "Caption is required."],
      },
      ignoreFormEdit: false,
      routeLeaveAlertForm: false,
    };
  },

  watch: {
    form: {
      handler: function () {
        if (!this.ignoreFormEdit) {
          this.routeLeaveAlertForm = true;
        }
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    userNumberSelect({ name, iso2, dialCode }) {
      this.form.countryCode = "+" + dialCode;
    },
    getIsSupplier() {
      return (
        auth.user.account?.organization_registry.type_details.short_code ===
        "supplier"
      );
    },
    onSelect({ name, iso2, dialCode }, index) {
      this.form.contactobj = this.form.contactobj.map((item, ind) => {
        if (ind === index) {
          return {
            ...this.form.contactobj[index],
            country: iso2,
            dialCode: "+" + dialCode,
          };
        }
        return { ...item };
      });
    },

    getFactory() {
      const factoryId = this.$route.params.id;
      const apiUrl = `/api/factories/${factoryId}`;
      this.getIsSupplier();

      restAdapter
        .get(apiUrl)
        .then((res) => {
          this.imageUrl = res.data.logo
            ? this.baseUrl + "/api/factories/logo_show/" + res.data.logo
            : null;

          this.form.name = res.data.name;
          this.Show.name = res.data.name;
          this.form.companyMission = res.data.mission;
          this.Show.companyMission = res.data.mission;
          this.form.companyVision = res.data.vision;
          this.Show.companyVision = res.data.vision;

          this.form.valueAddedServices = res.data.value_added_services;
          this.Show.valueAddedServices = res.data.value_added_services;

          this.form.establishedCountry = res.data.established_country;
          this.Show.establishedCountry = res.data.established_country;

          this.form.establishedCity = res.data.established_city;
          this.Show.establishedCity = res.data.established_city;

          this.form.establishedYear = res.data.established_year;
          this.Show.establishedYear = res.data.established_year;

          this.form.registrationNumber = res.data.business_registration_number;
          this.Show.registrationNumber = res.data.business_registration_number;

          this.form.companyWebsite = res.data.website_url;
          this.Show.companyWebsite = res.data.website_url;

          this.form.sampleDevelopmentCondition =
            res.data.sample_development_condition;
          this.Show.sampleDevelopmentCondition =
            res.data.sample_development_condition;

          this.form.bulkManufacturingCondition =
            res.data.bulk_manufacturing_condition;
          this.Show.bulkManufacturingCondition =
            res.data.bulk_manufacturing_condition;

          this.form.employeeCount = res.data.number_of_employees;
          this.Show.employeeCount = res.data.number_of_employees;

          this.form.machineCount = res.data.number_of_machines;
          this.Show.machineCount = res.data.number_of_machines;

          this.form.productionCapacity = res.data.production_capacity;
          this.Show.productionCapacity = res.data.production_capacity;

          this.form.sampleLeadTime = res.data.sample_lead_time;
          this.Show.sampleLeadTime = res.data.sample_lead_time;

          this.form.productionLeadTime = res.data.production_lead_time;
          this.Show.productionLeadTime = res.data.production_lead_time;

          //Clear data
          this.Show.contactDetails = [];
          this.form.contactobj = [];
          this.Show.machines = [];
          this.form.machineobj = [];
          this.form.selectedProductTypes = [];
          this.Show.products = [];
          this.Show.images = [];

          //Contacts
          const countryCodes = codes.customList(
            "countryCode",
            "+{countryCallingCode}"
          );
          const obj = Object.entries(countryCodes);
          this.Show.contactDetails = [];
          this.form.contactobj = [];

          if (
            res.data.contact_details &&
            Array.isArray(res.data.contact_details)
          ) {
            for (let i = 0; i < res.data.contact_details.length; i++) {
              this.Show.contactDetails.push(res.data.contact_details[i]);
              if (res.data.contact_details[i].mobile_number) {
                const countryCode =
                  res.data.contact_details[i].mobile_number.split(" ")[0];
                const phoneNumber =
                  res.data.contact_details[i].mobile_number.split(" ")[1];
                const selectedCountry = obj.find(
                  (country) => country[1] == countryCode
                );

                this.form.contactobj.push({
                  name: res.data.contact_details[i].name,
                  title: res.data.contact_details[i].designation,
                  email: res.data.contact_details[i].email,
                  country: selectedCountry ? selectedCountry[0] : false,
                  dialCode: selectedCountry ? countryCode : "",
                  number: selectedCountry
                    ? phoneNumber
                    : res.data.contact_details[i].mobile_number,
                });
              }
            }
          }

          this.Show.machines = [];
          this.form.machineobj = [];

          if (res.data.machine_types && Array.isArray(res.data.machine_types)) {
            for (let i = 0; i < res.data.machine_types.length; i++) {
              this.Show.machines.push(res.data.machine_types[i]);
              this.form.machineobj.push({
                type: res.data.machine_types[i].machine_type,
                amount: res.data.machine_types[i].number_of_machines,
              });
            }
          }

          this.form.selectedProductTypes = [];
          this.Show.products = [];

          if (
            res.data.company_products &&
            Array.isArray(res.data.company_products)
          ) {
            for (let i = 0; i < res.data.company_products.length; i++) {
              this.form.selectedProductTypes.push(
                res.data.company_products[i].product_id
              );
              this.Show.products.push(res.data.company_products[i].product_id);
            }
          }

          this.Show.images = [];
          this.Show.companyImages = [];
          this.Show.productImages = [];
          this.Show.certificationImages = [];

          if (
            res.data.gallery_image_data &&
            Array.isArray(res.data.gallery_image_data)
          ) {
            for (let i = 0; i < res.data.gallery_image_data.length; i++) {
              this.Show.images.push(res.data.gallery_image_data[i]);

              if (res.data.gallery_image_data[i].file_type == 1) {
                this.Show.companyImages.push(res.data.gallery_image_data[i]);
              } else if (res.data.gallery_image_data[i].file_type == 2) {
                this.Show.productImages.push(res.data.gallery_image_data[i]);
              } else if (res.data.gallery_image_data[i].file_type == 3) {
                this.Show.certificationImages.push({
                  ...res.data.gallery_image_data[i],
                  expired: res.data.gallery_image_data[i].expire_date
                    ? new Date(res.data.gallery_image_data[i].expire_date) <=
                      new Date()
                    : false,
                });
              }
            }
          }

          this.ignoreFormEdit = true;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong", error);
          }
        });
    },
    PhoneInputNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    editLogo() {
      this.$refs.logoInput.click();
    },
    updateFactoryLogo(file) {
      const formData = new FormData();
      formData.append("image", file);
      restAdapter
        .post(`/api/factories/${this.$route.params.id}/logo`, formData)
        .then(() => {
          notification.success("Logo updated");
          this.getFactory();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (error.response.data && error.response.data.error) {
              notification.error(error.response.data.error);
            } else {
              notification.error("Bad Request: The request is invalid.");
            }
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    handleLogoUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const size = file.size / 1024 / 1024;
        if (size > 5) {
          notification.error("File size should be less than 5MB");
          return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      this.updateFactoryLogo(file);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    clearFiles() {
      this.$refs.images.value = null;
    },
    uploadFiles() {
      for (let i = 0; i < this.$refs.images.files.length; i++) {
        formData.append(`image[${i}]`, this.form.machineobj[i].type);
      }
    },
    getImage() {
      this.imageUrl = this.baseUrl + "/api/user/image_show/" + this.user.id;
    },
    updateCompany() {
      if (!this.$refs.CompanyDetails.validate()) {
        return;
      }

      const requestData = {
        business_registration_number: this.form.registrationNumber,
        website_url: this.form.companyWebsite,
        name: this.form.name,
        established_year: this.form.establishedYear,
        established_country: this.form.establishedCountry,
        established_city: this.form.establishedCity,
        vision: this.form.companyVision,
        mission: this.form.companyMission,
        value_added_services: this.form.valueAddedServices,
        number_of_machines: this.form.machineCount,
        number_of_employees: this.form.employeeCount,
        production_capacity: this.form.productionCapacity,
        sample_lead_time: this.form.sampleLeadTime,
        production_lead_time: this.form.productionLeadTime,
        sample_development_condition: this.form.sampleDevelopmentCondition,
        bulk_manufacturing_condition: this.form.bulkManufacturingCondition,
        selected_product_types: this.form.selectedProductTypes,
      };

      const apiUrl = `/api/factories/${this.$route.params.id}`;

      restAdapter
        .put(apiUrl, requestData)
        .then(() => {
          notification.success(
            `${this.getIsSupplier() ? "Factory" : "Store"} details updated`
          );
          this.getFactory();
          this.edittwo = false;
          this.userCards();
          this.routeLeaveAlertForm = false;
          resetLeaveAlert();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            if (error.response.data && error.response.data.error) {
              notification.error(error.response.data.error);
            } else {
              notification.error("Bad Request: The request is invalid.");
            }
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },

    updateMachine() {
      if (!this.$refs.machineDetails.validate()) {
        return;
      }
      const factoryId = this.$route.params.id;

      restAdapter
        .post(`/api/factories/${factoryId}/machine_types`, {
          machine_types: this.form.machineobj.map((machine) => ({
            machine_type: machine.type,
            number_of_machines: machine.amount,
          })),
        })
        .then(() => {
          notification.success("Machines Saved");
          this.getFactory();
          this.editfive = false;
          this.routeLeaveAlertForm = false;
          resetLeaveAlert();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },

    updateContacts() {
      if (!this.$refs.contactDetails.validate()) {
        return;
      }

      const factoryId = this.$route.params.id;

      restAdapter
        .post(`/api/factories/${factoryId}/contact_details`, {
          contact_details: this.form.contactobj.map((contact) => ({
            name: contact.name,
            designation: contact.title,
            email: contact.email,
            mobile_number: contact.dialCode + " " + contact.number,
          })),
        })
        .then(() => {
          notification.success("Contact Added");
          this.getFactory();
          this.editfour = false;
          this.routeLeaveAlertForm = false;
          resetLeaveAlert();
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.updateProfilePicture();
      }
    },
    addMachineInput() {
      this.form.machineobj.push({
        type: "",
        amount: "",
      });
    },
    removeMachineInput() {
      if (this.form.machineobj.length > 1) {
        this.form.machineobj.pop();
      }
    },
    addContactInput() {
      this.form.contactobj.push({
        name: "",
        title: "",
        email: "",
        country: false,
        number: "",
      });
    },
    removeContactInput() {
      if (this.form.contactobj.length > 1) {
        this.form.contactobj.pop();
      }
    },
    closePicker(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (date !== null) {
        return this.$moment(new Date(date)).format("YYYY-MM-DD");
      }
      return null;
    },
    getProductTypeName(id) {
      const productType = this.productTypes.find(
        (productType) => productType.id == id
      );
      return productType && productType.name;
    },
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedFileTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    changePhoneNumber() {
      this.form.phoneNumber = "";
    },

    editoneClicked() {
      this.editone = true;
      this.companyOverview = false;
      this.contactInfo = false;
      this.machineInfo = false;
      this.companyGallery = false;
      this.UserProfileCard = false;
    },

    editoneClosed() {
      this.editone = false;
      this.userCards();
    },

    userCards() {
      this.companyOverview = true;
      this.contactInfo = true;
      this.machineInfo = true;
      this.companyGallery = true;
      this.UserProfileCard = true;
    },

    edittwoClicked() {
      this.edittwo = true;
      this.companyOverview = false;
      this.contactInfo = false;
      this.machineInfo = false;
      this.companyGallery = false;
      this.UserProfileCard = false;
    },

    edittwoClosed() {
      this.edittwo = false;
      this.userCards();
    },
  },
  computed: {
    billingAddressLineRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressLine1) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
  },

  mounted() {
    this.baseUrl = conf.default.server.host;
    this.getFactory();

    this.countries = countryList.getNames();
    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });

    const year = new Date().getFullYear();
    this.yearArray = Array.from(
      { length: year - 1900 },
      (value, index) => 1901 + index
    );
  },

  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>
<style lang="scss" scoped>
.mr-0 {
  margin-left: 5%;
}

.inlineBlock {
  min-width: 10%;
  float: left;
  padding: 5px;
}

.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.formLabel {
  font-size: 1rem !important;
}

.md-button.md-theme-default.md-raised:not([disabled]).md-primary {
  color: #fff;
  color: var(--md-theme-default-text-primary-on-primary, #fff);
  background-color: $tableBorder !important;
}
</style>
<style lang="scss">
.v-dialog-custom {
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .v-dialog-custom {
    width: 100% !important;
  }
}

.v-dialog-data {
  width: 80% !important;
  background-color: #fff !important;
  overflow: visible !important;
}

.v-card-data {
  max-height: 65vh !important;
  overflow: visible;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}

.name_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 12px 0px 0px 0px;
}

.image_style {
  border: 7px solid $paginateText;
  box-shadow: 0px 6.5045px 4.8836px 1px rgb(61 77 135 / 10%);
}

.preview_image_style {
  border: unset;
  box-shadow: unset;
}

.divider_style {
  border-color: $primaryDark !important;
  margin: 25px 25px 15px 25px;
}

.dialog_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 50px 0px;
}

.profile_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 20px 0px;
}

.company_overview_divider_style {
  border-color: $primaryDark !important;
  margin: -15px 25px 15px 32px;
}

.subtitle_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.subtitle_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.paragraph_data {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $tableBorder;
}

.btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding: 0px 5px 0px 5px;
}
.upload-btn-container {
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  padding: 0;
}

.edit-btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -25px;
}

.edit_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

.btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  margin-right: 25px;
  // text-transform: capitalize;
}

.logo-btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -25px;
}

.logo_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 2px;
  height: 25px;
  padding: 0px 15px 0px 15px;
  // text-transform: capitalize;
}

.logo_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  margin-right: 25px;
  // text-transform: capitalize;
}

.user-details-container {
  background-color: $paginateText;
  display: flex;
  justify-content: center;
}

.user-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.company-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.address-container {
  background-color: $paginateBackground;
  border: 0.5px solid $tableBorder;
  border-radius: 3px;
  text-align: left;
  padding: 20px 50px;
  color: $tableBorder;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.theme--light.v-input input {
  color: $primaryDark !important;
}

.addess-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $tableBorder;
  margin-bottom: 10px;
  margin-left: -15px;
}

.close-btn {
  color: $tableBorder !important;
  background-color: $paginateText;
  border: 1px solid $tableBorder;
  border-radius: 3px;
  height: 40px;
  // padding: 0px 15px 0px 15px;
  // text-transform: capitalize;
}

.save-btn {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

.policy-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: $tableBorder;
}

.v-application--is-ltr .v-text-field .v-label {
  color: $primaryDark !important;
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: $primaryDark !important;
}

// .v-overlay__scrim{
//   opacity: unset !important;
//   background: rgba(15, 5, 29, 0.78) !important;
//   backdrop-filter: blur(12px);
// }

.theme--dark.v-overlay {
  background-color: rgba(15, 5, 29, 0.78) !important;
}

.form-label-style {
  color: $tableBorder;
  opacity: 0.7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 0.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.products-container {
  background-color: #f6f1ff;
  width: auto;
  height: auto;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  padding: 22px 30px;
  margin: -12px 25px;
  overflow-y: auto;
}

.data-margin {
  margin: 15px 0px;
}

.data-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
}

.images-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.profile-container-card {
  background: $paginateBackground !important;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border: 1px solid $tableBorder !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.machines-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 60px;
}

.profile-card-style {
  margin-right: 10px;
  width: 100%;
  min-height: 52px;
  border-radius: 3px;
  border: 0.5px solid $light;
  background: $light;
  display: flex;
  align-items: center;
  text-align: left;
}
.upload-new {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

@media only screen and (max-width: 840px) {
  .upload-new {
    font-size: 11px;
  }
}

@media only screen and (max-width: 680px) {
  .upload-new {
    width: 91% !important;
    font-size: 9px;
  }
}

.machines-data-container {
  padding: 0px 50px;
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-hr {
  border-color: $primaryDark !important;
  margin: 10px 0px;
}

.machines-container-alignment {
  margin: -8px 0px;
}

.machine-type {
  margin: 0px 0px 0px 12px;
}

.theme--light.v-select .v-select__selections {
  color: $primaryDark !important;
}

.theme--light.v-input textarea {
  color: $primaryDark !important;
}

.team-details {
  padding: 25px;
  margin-top: -15px;
}

.machine-details {
  padding: 25px;
}

.add-remove-btn {
  height: 32px !important;
  width: 32px !important;
  box-shadow: none;
  border-radius: 3.99038px;
}

.title_align {
  margin-left: 16px;
}

.vue-country-select {
  border: 1px solid $tableBorder !important;
}

.vue-country-select:focus-within {
  border-color: $success !important;
}

.more-info-style {
  background: rgba(112, 208, 206, 0.18);
  border-radius: 3px;
  margin-right: 10px;
  width: 80%;
  min-height: 52px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 32px;
  line-break: anywhere;
}

.profile-col {
  margin: -12px 0px 0px 0px;
}

.md-field.md-theme-default:after {
  background-color: $tableBorder;
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: $tableBorder !important;
}

.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}

.number-and-date-container {
  padding: 12px;
}

.theme--light.v-icon {
  color: $primaryDark;
}

.overview-subtitle {
  margin: 0px 15px;
}

.row-alignment {
  margin: -12px 20px;
}

.card-height {
  height: 100%;
}
</style>

<style scoped>
.v-application .v-list .v-list-item--active {
  background-color: #e2daed !important;
}

.theme--light.v-list {
  background: #f3edf7;
  color: #473068;
}

.show-btn {
  background-color: #473068 !important;
}

.calendar-field::v-deep .v-text-field--outlined fieldset {
  height: 45px !important;
}

.calendar-field::v-deep .v-text-field--outlined .v-text-field__slot {
  height: 42px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 9px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 9px !important;
}

.calendar-field-date-picker {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.flag-container {
  height: 40px;
}

.flag-container-col {
  display: contents;
}

.phone-number-row {
  margin: -12px 0px 0px 2px;
}
</style>
